import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import w2eImg from "../assets/img/W2E-.png";
import googlePlayIcon from '../assets/img/nav-icon8.png';
import appStoreIcon from '../assets/img/nav-icon9.png';
import { Helmet } from 'react-helmet';


const Download = () => {
  return (
    <section className="download">
      <Helmet>
        <meta name="description" content="Take the first step into the enchanting world of War2Escape by downloading the game on your device. Join thousands of players worldwide in an epic quest for victory and glory. Explore magical landscapes and conquer challenging levels as you journey towards the pinnacle of victory." />
        <meta name="keywords" content="Download War2Escape, War2Escape Download, download war2escape, war 2 escape download, War2Escape download, mobile game download, escape game download, W2E download" />
        <title>Download the Game Now! - War2Escape</title>
      </Helmet>
      <Container>
        <Col className="aboutme-background">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="text-center">
                <img src={w2eImg} alt="War2Escape Icon" className="game-icon" />
                <h2 className="mt-4">Download the Game Now!</h2>
                <p>
                Take the first step into the enchanting world of War2Escape by downloading the game on your device. Join thousands of players worldwide in an epic quest for victory and glory. Explore magical landscapes and conquer challenging levels as you journey towards the pinnacle of victory.
                </p>
                <p>
                Customize your character with a vast array of weapons and magical attire, each offering unique abilities and advantages. Compete in ranked matches to climb the leaderboard and earn rewards, from coins to exclusive character unlocks. With captivating gameplay suitable for all ages, War2Escape promises endless hours of entertainment and excitement.
                </p>
                <p>
                Don't miss out on the adventure of a lifetime. Download War2Escape now and embark on a journey filled with magic, mystery, and endless possibilities.                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} className="text-center mb-4">
              <h3>Download on the App Store</h3>
              <a href="https://apps.apple.com/us/app/war2escape/id6541765555" className="app-download-link">
                <img src={appStoreIcon} alt="App Store Icon" className="app-store-icon" /> 
              </a>
            </Col>
            <Col md={6} className="text-center mb-4">
              <h3>Get it on Google Play</h3>
              <a href="https://play.google.com/store/apps/details?id=com.Comp2E.War2Escape" className="app-download-link">
                <img src={googlePlayIcon} alt="Google Play Icon" className="google-play-icon" /> 
              </a>
            </Col>
          </Row>
        </Col>
      </Container>
    </section>
  );
}

export default Download;
