import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [statusVariant, setStatusVariant] = useState(''); // 'success' or 'danger'
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://war2escape-6b76ef259e96.herokuapp.com/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.text();
      setStatusMessage('Message sent successfully!');
      setStatusVariant('success');
      setIsSubmitted(true); // Set form as submitted on success
    } catch (error) {
      console.error('Error:', error); // Log the error
      setStatusMessage('There was an error sending your message. Please try again later.');
      setStatusVariant('danger');
    }
  };  
  

  return (
    <section id="contact" className="contact">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h2 className="section-title">Contact Us</h2>
            <p className="section-description">If you have any questions, feedback, or need assistance, feel free to reach out to us.</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required disabled={isSubmitted} />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required disabled={isSubmitted} />
              </Form.Group>
              <Form.Group controlId="formSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" name="subject" value={formData.subject} onChange={handleChange} required disabled={isSubmitted} />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={5} name="message" value={formData.message} onChange={handleChange} required disabled={isSubmitted} />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isSubmitted}>
                {isSubmitted ? 'Submitted' : 'Submit'}
              </Button>
            </Form>
            {statusMessage && (
              <Alert variant={statusVariant} onClose={() => setStatusMessage('')} dismissible className="mt-3">
                {statusMessage}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
