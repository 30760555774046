import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import joyVideo from "../assets/video/Joy.mp4";
import rainVideo from "../assets/video/Rain.mp4";
import luisVideo from "../assets/video/Luis.mp4";
import shineVideo from "../assets/video/Shine.mp4";
import colorSharp2 from "../assets/img/color-back4.png";

export const Characters = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const characters = [
    { name: "Joy", video: joyVideo, description: "With his adventurous spirit and magnetic charisma, Joy excels in navigating challenging quests. Joy's unwavering confidence and charm make him a formidable contender in the race to the teleport." },
    { name: "Rain", video: rainVideo, description: "Rain's smile can light up even the darkest of days. Her unwavering optimism and ability to stay positive are crucial for overcoming the toughest challenges. Choose Rain to brighten your path to the teleport." },
    { name: "Luis", video: luisVideo, description: "Luis's loud presence and passionate spirit drive him to push beyond limits. His intense focus and fierce determination make him a powerful competitor. Choose Luis to power your way to the top." },
    { name: "Shine", video: shineVideo, description: "True to her name, Shine radiates warmth and positivity. Her shy demeanor hides a resilient strength that shines through in adversity. Shine's gentle nature and inner charisma make her a beacon of hope. Choose Shine to light your way to the teleport and beyond." }
  ];

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  useEffect(() => {
    setSelectedCharacter(characters[0]);
  }, []);

  return (
    <div style={{ marginTop: '100px' }}>
      <section className="character" style={{ backgroundImage: `url(${colorSharp2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Container>
          <Row>
            <Col size={12} id="characters">
              <h2>Characters</h2>
              <p>Learn more about the characters in War2Escape game:</p>
              <Tab.Container id="characters-tabs" defaultActiveKey="Joy">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                  {characters.map((character, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={character.name} onClick={() => handleCharacterClick(character)}>{character.name}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content>
                  {characters.map((character, index) => (
                    <Tab.Pane key={index} eventKey={character.name}>
                      <Row className="justify-content-center">
                        <Col md={6}>
                          {selectedCharacter?.name === character.name && (
                            <video className="character-video" src={character.video} autoPlay loop muted playsInline />
                          )}
                        </Col>
                        <Col md={6}>
                          <div className="character-description">
                            <h3>{character.name}</h3>
                            <p>{character.description}</p>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Characters;
