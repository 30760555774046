
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBqTq69tEAbpIm76cKLC-jcODeL5G9tqBU",
    authDomain: "my-project-b4fec.firebaseapp.com",
    databaseURL: "https://my-project-b4fec-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "my-project-b4fec",
    storageBucket: "my-project-b4fec.appspot.com",
    messagingSenderId: "172231203654",
    appId: "1:172231203654:web:bfea2ee1fcf9fe3ba36347",
    measurementId: "G-L5QZE8K468"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };