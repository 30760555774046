import React, { useRef, useState } from 'react'; // Add useState here
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import gameImage from "../assets/img/game-image1.png";
import officialTrailer from "../assets/video/OfficialTrailer.mp4";
import mapsTrailer from "../assets/video/MapsTrailer.mp4";
import playingTrailer from "../assets/video/PlayingTrailer.mp4";

const Trailers = () => {
  const sliderRef = useRef(null);
  const videosRef = useRef([]);
  const [activeVideos, setActiveVideos] = useState([true, false, true]); // Control which videos are active

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipe: true,
    swipeToSlide: true,
    easing: 'ease-in-out',
    beforeChange: (current, next) => {
      const currentVideo = videosRef.current[current];
      if (currentVideo) {
        currentVideo.pause();
        currentVideo.currentTime = 0;
      }
    },
    afterChange: (current) => {
      const currentVideo = videosRef.current[current];
      if (currentVideo) {
        currentVideo.play();
      }
    }
  };

  const handleVideoRef = (index) => (el) => {
    videosRef.current[index] = el;
    if (el) {
      el.addEventListener('play', () => {
        videosRef.current.forEach((video, idx) => {
          if (idx !== index && video) {
            video.pause();
            video.currentTime = 0;
          }
        });
      });
    }
  };

  return (
    <section className="trailer">
      <img className="main-image" src={gameImage} alt="Game Image" />
      <Container>
        <div className="trailer-bx">
          <h2>Trailers</h2>
          <p>Check out the exciting trailers of War2Escape below:</p>
          <Slider {...settings} className="trailer-slider" ref={sliderRef}>
            {activeVideos[0] && (
              <div>
                <div className="trailer-item">
                  <video className="trailer-video" controls ref={handleVideoRef(0)}>
                    <source src={officialTrailer} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
            {activeVideos[1] && (
              <div>
                <div className="trailer-item">
                  <video className="trailer-video" controls ref={handleVideoRef(1)}>
                    <source src={mapsTrailer} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
            {activeVideos[2] && (
              <div>
                <div className="trailer-item">
                  <video className="trailer-video" controls ref={handleVideoRef(2)}>
                    <source src={playingTrailer} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default Trailers;
