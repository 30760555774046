import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import navIcon1 from '../assets/img/nav-icon6.png';
import navIcon2 from '../assets/img/nav-icon7.png';
import navIcon3 from '../assets/img/nav-icon11.png';
import twoELogo from '../assets/img/2ELogo.png'; // Import the 2E logo image
import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

const NavBar = ({ setActiveLink, activeLink }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !isMobileNavOpen) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobileNavOpen]);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
    if (!isMobileNavOpen) {
      document.body.classList.add('mobile-nav-open');
    } else {
      document.body.classList.remove('mobile-nav-open');
    }
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
    document.body.classList.remove('mobile-nav-open');
    setScrolled(window.scrollY > 50);
  };

  const handleNavClick = (link) => {
    setActiveLink(link);
    closeMobileNav();
  };

  return (
    <Navbar expand="lg" className={scrolled || isMobileNavOpen ? "scrolled" : ""} collapseOnSelect>
      <Container>
        <Navbar.Brand href="https://www.instagram.com/2e.games/">
          <img src={twoELogo} alt="2E Logo" className="twoELogo" style={{ boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.5)', width: '28%' }} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMobileNav} />
        <Navbar.Collapse id="basic-navbar-nav" in={isMobileNavOpen}>
          <Nav className="me-auto">
            <NavLink
              exact="true"
              to="/"
              className={({ isActive }) => (isActive ? 'active navbar-link nav-link active' : 'navbar-link nav-link')}
              onClick={() => handleNavClick('home')}
            >
              Home
            </NavLink>
            <NavLink
              to="/trailers"
              className={({ isActive }) => (isActive ? 'active navbar-link nav-link active' : 'navbar-link nav-link')}
              onClick={() => handleNavClick('trailers')}
            >
              Trailers
            </NavLink>
            <NavLink
              to="/characters"
              className={({ isActive }) => (isActive ? 'active navbar-link nav-link active' : 'navbar-link nav-link')}
              onClick={() => handleNavClick('characters')}
            >
              Characters
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? 'active navbar-link nav-link active' : 'navbar-link nav-link')}
              onClick={() => handleNavClick('contact')}
            >
              Contact
            </NavLink>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.instagram.com/war2escape/" target="_blank" rel="noopener noreferrer">
                <img src={navIcon1} alt="Instagram" />
              </a>
              {/* <a href="https://www.linkedin.com/in/maor-moav/" target="_blank" rel="noopener noreferrer">
                <img src={navIcon2} alt="Tweeter" />
              </a> */}
              <a href="https://www.tiktok.com/@war2escape" target="_blank" rel="noopener noreferrer">
                <img src={navIcon3} alt="TikTok" />
              </a>
            </div>
            <NavLink
              to="/download"
              className="navbar-link download-link vvd-link" // Ensure to add the vvd-link class for styling
              activeClassName="active"
              onClick={() => handleNavClick('download')}
            >
              <button className="vvd"><span>Download</span></button>
            </NavLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
