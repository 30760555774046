import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Banner } from './components/Banner';
import Trailers from './components/Trailers';
import { Characters } from './components/Characters';
import Download from './components/Download';
import Contact from './components/Contact';
import NavBar from './components/NavBar';
import ScrollToTop from './components/ScrollToTop';
import HandleAction from './components/HandleAction';
import DeleteAccount from './components/DeleteAccount'; // Import the new component

function App() {
  const [activeLink, setActiveLink] = useState('home');

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <NavBar setActiveLink={setActiveLink} activeLink={activeLink} />
        <Routes>
          <Route path="/" element={<Banner setActiveLink={setActiveLink} activeLink={activeLink} />} />
          <Route path="/trailers" element={<Trailers />} />
          <Route path="/characters" element={<Characters />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/download" element={<Download />} />
          <Route path="/handle-action" element={<HandleAction />} />
          <Route path="/delete-account" element={<DeleteAccount />} /> 
          <Route path="*" element={<Banner setActiveLink={setActiveLink} activeLink={activeLink} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

