import { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import { ArrowDownCircle } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import w2eMobile from "../assets/img/war2escpemob.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Helmet } from 'react-helmet';

export const Banner = ({ setActiveLink, activeLink }) => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState('');
  const toRotate = ["Welcome To War2Escape!"];
  const period = 2000;

  const navigate = useNavigate();

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  const handleDownloadClick = () => {
    setActiveLink('download');
    navigate('/download');
  };

  return (
    <section className="banner" id="home">
      <Helmet>
        <meta name="description" content="Embark on an epic journey through mystical realms and magical landscapes in War2Escape. Jump through levels on enchanted maps, aiming to reach the pinnacle where a teleport of blue magic awaits. Compete with players worldwide to ascend the ranks from Beginner to Diamond, mastering challenges and collecting rewards along the way." />
        <meta name="keywords" content="W2E, war to escape, war 2 escape, War2Escape, War 2 Escape, multiplayer game, escape game, magical landscapes, mystical realms" />
        <title>War2Escape</title>
      </Helmet>
      <Container>
        <Col className="aboutme-background">
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>
                  {``}
                  <span className="txt-rotate" dataPeriod="200" data-rotate='[Welcome To War2Escape!]'>
                    <span className="wrap">{text}</span>
                  </span>
                </h1>
                <div className="img1">
                  <img src={w2eMobile} alt="War2Escape Mobile" />
                </div>
                <p>
                  Welcome to the enchanting world of War2Escape, created by 2E, a game studio dedicated to delivering immersive gaming experiences. Embark on an epic journey through mystical realms and magical landscapes in War2Escape. Jump through levels on enchanted maps, aiming to reach the pinnacle where a teleport of blue magic awaits. Compete with players worldwide to ascend the ranks from Beginner to Diamond, mastering challenges and collecting rewards along the way.
                </p>
                <p>
                  Customize your character with powerful weapons and magical attire, unlocking new abilities and characters as you progress. Challenge yourself with increasingly difficult levels and compete in ranked matches to prove your skills. Suitable for players of all ages, War2Escape offers endless excitement and entertainment for everyone.
                </p>
                <p>
                  Join the adventure and download War2Escape today to immerse yourself in an unforgettable gaming experience. Are you ready to escape into a world of magic and adventure?
                </p>
                <button onClick={handleDownloadClick}> Download <ArrowDownCircle size={25} /></button>
              </div>
            }
          </TrackVisibility>
        </Col>
      </Container>
    </section>
  );
}
