import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { decryptUserID } from '../utils/decryptUserID'; // Utility function to decrypt userID

const DeleteAccount = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusVariant, setStatusVariant] = useState('');
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [usernameInput, setUsernameInput] = useState(''); // Store the entered username
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encryptedUserID = queryParams.get('uid');
  const decryptedUserID = encryptedUserID ? decryptUserID(encryptedUserID) : null;

  useEffect(() => {
    if (!encryptedUserID) {
      setStatusMessage('For a deletion request, please log in to the game and press the "Deletion Request" button in your profile.');
      setStatusVariant('info');
    }
  }, [encryptedUserID]);

  const handleDeleteUserRequest = async () => {
    if (!usernameInput) {
      setStatusMessage('Please enter your username.');
      setStatusVariant('danger');
      return;
    }

    setDeleteButtonDisabled(true);

    try {
      const deleteRequest = {
        username: usernameInput,  // Change 'name' to 'username' to match the backend
        userID: decryptedUserID,
        subject: `Delete Account Request for ${usernameInput}`,
        message: `User ${usernameInput} (UID: ${decryptedUserID}) has requested to delete their account.`,
      };

      // Send the email to support
      const response = await fetch('https://war2escape-6b76ef259e96.herokuapp.com/delete-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteRequest),
      });

      if (response.ok) {
        setStatusMessage('Your account deletion request has been sent. We will process it as soon as possible.');
        setStatusVariant('success');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        throw new Error('Failed to send the request.');
      }
    } catch (error) {
      setStatusMessage(`Error: ${error.message}`);
      setStatusVariant('danger');
      setDeleteButtonDisabled(false);
    }
  };

  return (
    <section id="user-info-actions" className="user-info-actions">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h2 className="section-title">Delete Account</h2>
            {statusMessage && (
              <Alert variant={statusVariant} onClose={() => setStatusMessage('')} dismissible className="mt-3">
                {statusMessage}
              </Alert>
            )}
            {!encryptedUserID ? (
              <p></p>
            ) : (
              <div className="delete-container">
                <p className="important-text">Are you sure you want to delete your account and all associated data?</p>
                <ul className="delete-list">
                  <li>Personal Identifiable Information (PII): Email Address, Username</li>
                  <li>User Profile Data: Player Characteristics, Inventory Items</li>
                  <li>Transaction Data: Purchase History</li>
                  <li>Game Progress and Stats: Achievements and Levels, High Scores</li>
                  <li>Friends and Social Data: Friend Lists, Messages</li>
                </ul>
                <Form.Group controlId="formUsername">
                  <Form.Label>Please enter your username to confirm</Form.Label>
                  <Form.Control
                    type="text"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                    placeholder="Enter your username"
                    required
                  />
                </Form.Group>
                <div className="btn-container">
                  <Button
                    onClick={handleDeleteUserRequest}
                    variant="danger"
                    disabled={deleteButtonDisabled}
                  >
                    Yes, Delete My Account
                  </Button>
                  <Button onClick={() => navigate('/')} variant="secondary">
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DeleteAccount;







// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Button, Alert, Form } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { auth } from '../firebase'; // Adjust the import path as needed
// import { signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
// import { getDatabase, ref, get } from 'firebase/database';

// const UserInfoAndActions = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [userInfo, setUserInfo] = useState(null);
//   const [statusMessage, setStatusMessage] = useState('');
//   const [statusVariant, setStatusVariant] = useState(''); // 'success' or 'danger'
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false); // New state
//   const navigate = useNavigate();

//   useEffect(() => {
//     const currentUser = auth.currentUser;
//     if (currentUser) {
//       fetchUserData(currentUser.uid);
//       setIsAuthenticated(true);
//     }
//   }, []);

//   const fetchUserData = async (uid) => {
//     const db = getDatabase();
//     const userRef = ref(db, `users/${uid}`);
//     const snapshot = await get(userRef);
//     if (snapshot.exists()) {
//       setUserInfo(snapshot.val());
//     } else {
//       setStatusMessage('No user data found.');
//       setStatusVariant('danger');
//     }
//   };

//   const handleLogin = async () => {
//     try {
//       const userCredential = await signInWithEmailAndPassword(auth, email, password);
//       fetchUserData(userCredential.user.uid);
//       setIsAuthenticated(true);
//       setStatusMessage('User logged in successfully!');
//       setStatusVariant('success');
//     } catch (error) {
//       let errorMessage = 'Error: ' + error.message;

//       if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found' || error.code === "auth/invalid-email") {
//         errorMessage = 'Wrong Email/Password';
//       }

//       setStatusMessage(errorMessage);
//       setStatusVariant('danger');
//     }
//   };

//   const handleDeleteUser = async () => {
//     setDeleteButtonDisabled(true); // Disable the button
//     try {
//       const db = getDatabase();
//       const userStatusRef = ref(db, `users/${auth.currentUser.uid}/Status`);
//       const statusSnapshot = await get(userStatusRef);

//       if (statusSnapshot.exists() && statusSnapshot.val() !== 'Offline') {
//         setStatusMessage('Please log out before attempting to delete your account.');
//         setStatusVariant('danger');
//         setDeleteButtonDisabled(false); // Re-enable the button
//         return;
//       }

//       // Trigger Cloud Function by deleting the user from Auth
//       await deleteUser(auth.currentUser);

//       setStatusMessage('Your account deletion request has been received and will be processed shortly.');
//       setStatusVariant('success');

//       setTimeout(() => {
//         navigate('/');
//       }, 2000);
//     } catch (error) {
//       setStatusMessage(`Error: ${error.message}`);
//       setStatusVariant('danger');
//       setDeleteButtonDisabled(false); // Re-enable the button if there's an error
//     }
//   };

//   return (
//     <section id="user-info-actions" className="user-info-actions">
//       <Container>
//         <Row className="justify-content-center">
//           <Col md={8}>
//             <h2 className="section-title">User Info and Actions</h2>
//             {statusMessage && (
//               <Alert variant={statusVariant} onClose={() => setStatusMessage('')} dismissible className="mt-3">
//                 {statusMessage}
//               </Alert>
//             )}
//             {!isAuthenticated ? (
//               <div className="login-form">
//                 <Form.Group controlId="formEmail" className="mb-3">
//                   <Form.Label>Email</Form.Label>
//                   <Form.Control
//                     type="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Enter your email"
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     placeholder="Enter your password"
//                     required
//                   />
//                 </Form.Group>
//                 <Button onClick={handleLogin} variant="primary" className="mb-3">
//                   Login
//                 </Button>
//               </div>
//             ) : (
//               <div>
//                 <h4>User Information</h4>
//                 {auth.currentUser && <p>Email: {auth.currentUser.email}</p>}
//                 {userInfo && (
//                   <>
//                     <p>Username: {userInfo.username}</p>
//                     <p>Level: {userInfo.level}</p>
//                   </>
//                 )}
//                 {!confirmDelete ? (
//                   <Button onClick={() => setConfirmDelete(true)} variant="danger" className="mb-3">
//                     Delete User
//                   </Button>
//                 ) : (
//                   <div className="delete-container">
//                     <p className="important-text">Are you sure you want to delete your account and all associated data?</p>
//                     <ul className="delete-list">
//                       <li>Personal Identifiable Information (PII): Email Address, Username, Password</li>
//                       <li>User Profile Data: Player Characteristics, Inventory Items</li>
//                       <li>Transaction Data: Purchase History</li>
//                       <li>Game Progress and Stats: Achievements and Levels, High Scores</li>
//                       <li>Friends and Social Data: Friend Lists, Messages</li>
//                     </ul>
//                     <div className="btn-container">
//                       <Button onClick={handleDeleteUser} variant="danger" className="mr-2" disabled={deleteButtonDisabled}>
//                         Yes, Delete My Account
//                       </Button>
//                       <Button onClick={() => setConfirmDelete(false)} variant="secondary">
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default UserInfoAndActions;
