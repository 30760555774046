import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the import path as needed
import { applyActionCode, confirmPasswordReset } from 'firebase/auth';

const HandleAction = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusVariant, setStatusVariant] = useState(''); // 'success' or 'danger'
  const [actionMode, setActionMode] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const code = urlParams.get('oobCode');

    if (!mode || !code) {
      // Redirect to home page if mode or oobCode is not present
      navigate('/');
    } else {
      setActionMode(mode);
      setOobCode(code);
      if (mode === 'verifyEmail') {
        handleVerifyEmail(code);
      }
    }
  }, [navigate]);

  const isPasswordStrong = (password) => {
    const hasUpperChar = /[A-Z]/.test(password);
    const hasLowerChar = /[a-z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password.length >= 8;

    return hasUpperChar && hasLowerChar && hasDigit && hasSpecialChar && hasMinimumLength;
  };

  const handleVerifyEmail = async (code) => {
    try {
      await applyActionCode(auth, code);
      setStatusMessage('Email verification successful!');
      setStatusVariant('success');
      setTimeout(() => {
        navigate('/'); // Redirect to home page after 2 seconds
      }, 2000);
    } catch (error) {
      setStatusMessage(`Error: ${error.message}`);
      setStatusVariant('danger');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword.length > 100) {
      setStatusMessage('Password should not be longer than 100 characters!');
      setStatusVariant('danger');
      return;
    }

    if (!isPasswordStrong(newPassword)) {
      setStatusMessage('Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long!');
      setStatusVariant('danger');
      return;
    }

    if (newPassword !== confirmPassword) {
      setStatusMessage('Passwords do not match!');
      setStatusVariant('danger');
      return;
    }

    if (oobCode && newPassword) {
      try {
        await confirmPasswordReset(auth, oobCode, newPassword);
        setStatusMessage('Password reset successful!');
        setStatusVariant('success');
        setTimeout(() => {
          navigate('/'); // Redirect to home page after 2 seconds
        }, 2000);
      } catch (error) {
        setStatusMessage(`Error: ${error.message}`);
        setStatusVariant('danger');
      }
    } else {
      setStatusMessage('Invalid request or missing parameters.');
      setStatusVariant('danger');
    }
  };

  return (
    <section id="handle-action" className="handle-action"> {/* Use the handle-action class */}
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h2 className="section-title">
              {actionMode === 'verifyEmail' ? 'Email Verification' : 'Reset Password'}
            </h2>
            <p className="section-description">
              {statusMessage ? (
                <Alert variant={statusVariant} onClose={() => setStatusMessage('')} dismissible className="mt-3">
                  {statusMessage}
                </Alert>
              ) : actionMode === 'verifyEmail' ? (
                'Verifying your email, please wait...'
              ) : (
                'Enter your new password below to reset it.'
              )}
            </p>
            {actionMode === 'resetPassword' && (
              <Form onSubmit={handleResetPassword}>
                <Form.Group controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your new password"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Reset Password
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HandleAction;
